import {CommonModule} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatRadioModule} from '@angular/material/radio';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatButtonModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatIconModule,
  ],
  template: `
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <mat-dialog-content class="dialog-content">
      <div class="list-container">
        <div class="list-item" *ngFor="let value of list; let i = index">
          <span class="list-value">{{ value }}</span>
          <button mat-icon-button color="warn" (click)="removeAt(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button *ngIf="data.cancelText" [mat-dialog-close]="null">
        {{ data.cancelText }}
      </button>
      <button mat-button color="primary" [mat-dialog-close]="list">
        {{ data.okText }}
      </button>
    </mat-dialog-actions>
  `,
  styles: [`
    .dialog-content {
      padding: 16px;
      max-width: 400px;
    }

    .list-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .list-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
    }

    .list-value {
      font-size: 16px;
      color: #424242;
      padding-left: 0.5rem;
    }

    mat-dialog-actions {
      padding: 16px;
      display: flex;
      justify-content: flex-end;
    }
  `],
})
export class RemoveListDialogComponent implements OnInit {
  list: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<RemoveListDialogComponent, string[]>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.list = this.data.initValue;
  }

  removeAt(index: number) {
    this.list.splice(index, 1);
  }
}
