<div class="home-container" *ngIf="(user$| async) as user; else loading">
  <div class="home-title-section">
    <h1>Indosuara Employee Console</h1>
    <p>同合順企業股份有限公司 SUNRICH ENT CO., LTD.</p>
    <mat-divider></mat-divider>
  </div>
  <div class="home-greeting-section">
    <h3>Hi, {{ user.name || user.email }}!</h3>
  </div>

  <div class="quick-access-container" *ngIf="user">
    <h2>Quick Access</h2>

    <div class="quick-access-inner" >
      <ng-container *ngIf="accessList$ | async as accessList">
        <div class="quick-access-child" *ngFor="let node of accessList">
          <mat-card appearance="outlined">
            <mat-card-title>
              {{node.name}}
            </mat-card-title>
            <mat-divider></mat-divider>
            <mat-card-content>
              <mat-nav-list>
                <ng-container *ngFor="let page of node.children">
                  <ng-container *ngIf="page.children && page.children.length; else leafNode">
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          {{ page.name }}
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <ng-container *ngTemplateOutlet="nestedNode; context: { $implicit: page }"></ng-container>
                    </mat-expansion-panel>
                  </ng-container>
                  <ng-template #leafNode>
                    <a mat-list-item [routerLink]="page.route" *ngIf="page.route">
                      {{ page.name }}
                    </a>
                  </ng-template>
                </ng-container>
              </mat-nav-list>

              <ng-template #nestedNode let-node>
                <mat-nav-list>
                  <ng-container *ngFor="let page of node.children">
                    <ng-container *ngIf="page.children && page.children.length; else leafNode">
                      <mat-expansion-panel>
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            {{ page.name }}
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ng-container *ngTemplateOutlet="nestedNode; context: { $implicit: page }"></ng-container>
                      </mat-expansion-panel>
                    </ng-container>
                    <ng-template #leafNode>
                      <a mat-list-item [routerLink]="page.route" *ngIf="page.route">
                        {{ page.name }}
                      </a>
                    </ng-template>
                  </ng-container>
                </mat-nav-list>
              </ng-template>
            </mat-card-content>
          </mat-card>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #loading>
  Loading...
</ng-template>
