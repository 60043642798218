import {CommonModule} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatRadioModule} from '@angular/material/radio';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatButtonModule,
    MatRadioModule,
    ReactiveFormsModule,
  ],
  template: `
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <mat-dialog-content>
      <mat-radio-group [formControl]="checkoutForm.controls.shippingProvider">
        <mat-radio-button
          *ngFor="let provider of shippingProviders"
          [value]="provider.value">
            {{ provider.label }}
        </mat-radio-button>
      </mat-radio-group>
    </mat-dialog-content>
    <mat-dialog-actions>
      <span class="flex-spacer"></span>
      <button mat-button
        *ngIf="data.cancelText"
        [mat-dialog-close]="null"
      >
        {{ data.cancelText }}
      </button>
      <button mat-button
      color="primary"
      [mat-dialog-close]="checkoutForm.value.shippingProvider"
      >
        {{ data.okText }}
      </button>
    </mat-dialog-actions>
  `,
})
/**
 * a reusable dialog component ui
 */
export class AskShippingProviderDialogComponent implements OnInit {
  /**
   *
   * @param {MatDialogRef<AskShippingProviderDialogComponent, number>}dialogRef
   * @param {any} data
   */
  constructor(
    public dialogRef: MatDialogRef<AskShippingProviderDialogComponent, number>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit(): void {
    this.checkoutForm.controls.shippingProvider.setValue(this.data.initText);
  }

  checkoutForm = new FormGroup({
    shippingProvider: new FormControl<number>(0),
  });

  shippingProviders = [
    {value: 0, label: 'No Need to Ship'},
    {value: 1, label: 'Heimao'},
    {value: 5, label: '7-Eleven'},
    {value: 6, label: 'Family Mart'},
    {value: 7, label: 'Hi-Life'},
    {value: 8, label: 'OK Mart'},
  ];
}

